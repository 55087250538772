import React, { useState, useEffect } from 'react'
import { BigHead } from '@bigheads/core'
import { Tooltip as ReactTooltip } from "react-tooltip";


const InputData = ({ id, client, onClick, name, street, cpf, plan, mail, phone, category, number, service, goal, status, type, name_product, date,deadline }) => {

    const [usersInCountage, setUserInCountage] = useState([])
    const [image, setImage] = useState()
    const [addresses, setAddresses] = useState()

    // const getImage = async () => {
    //     const response = await api.post("client/immobiles/image/", {
    //         "id_prop": id_prop
    //     })

    //     setImage(response.data[0].image)
    // }

    // const getAddresses = async () => {
    //     const response = await api.get(`client/immobiles/addresses/${id_prop}`)
    //     if (response.status == 404) {
    //         return false
    //     }
    //     setAddresses(response.data[0].city)
    // }


    // // useEffect(() => {
    // //     getUsersInCountage()
    // // }, [])

    // useEffect(() => {
    //     getImage()
    //     getAddresses()
    // }, [id_prop])

    return (

        <div style={{ minWidth: "815px", width: usersInCountage.length == 1 || usersInCountage.length == 0 ? "190px" : "auto", height: "48px", border: "1px solid #D7D7D7", borderRadius: "7px", cursor: "pointer", background: "white", marginBottom: "2rem" }}>

            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>


                <div data-tooltip-place='top' data-tooltip-position-strategy='fixed' data-tooltip-id="name" style={{ display: "flex", alignItems: "center", height: "35px", marginTop: "0.3rem", marginLeft: "0.5rem", marginRight: "0.5rem", gap: "5px", padding: "0 0 0 12px", borderRadius: "60px" }}>
                    <ReactTooltip id='name' />
                    <p className='textCardUserTable' style={{ transform: "translateY(8px)" }}></p>


                    <div style={{ width: "37px" }}>

                    </div>


                    {type == "category"

                        ?

                        <>

                            <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{date}</p>

                            <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{name_product}</p>
                            <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>

                                {status == null || status == 1

                                    ?
                                    <button style={{ width: "106px", height: "28px", background: "#FFF7AA", border: "none", color: "#C6B200", fontSize: "11px", borderRadius: "60px" }}>Em Aberto</button>
                                    :

                                    status == 2

                                        ?
                                        <button style={{ width: "106px", height: "28px", background: "#E0FFF0", border: "none", color: "#00B386", fontSize: "11px", borderRadius: "60px" }}>Separando</button>

                                        :

                                        status == 3

                                            ?
                                            <button style={{ width: "106px", height: "28px", background: "#D7DDFF", border: "none", color: "#4F31F7", fontSize: "11px", borderRadius: "60px" }}>Produzindo</button>
                                            :
                                            status == 4

                                                ?

                                                <button style={{ width: "106px", height: "28px", background: "#FFF898", border: "none", color: "#E3A010", fontSize: "11px", borderRadius: "60px" }}>Embalando</button>
                                                :
                                                status == 5

                                                    ?

                                                    <button style={{ width: "106px", height: "28px", background: "#A3F9D0", border: "none", color: "#0A906E", fontSize: "11px", borderRadius: "60px" }}>Enviado</button>

                                                    :

                                                    status == 6

                                                        ?
                                                        <button style={{ width: "106px", height: "28px", background: "#FFE1E1", border: "none", color: "#EA0000", fontSize: "11px", borderRadius: "60px" }}>Cancelado</button>
                                                        :

                                                        <></>
                                }

                            </p>
                   
                            <button style={{ width: "106px", height: "28px", background: "#FFF7AA", border: "none", color: "#C6B200", fontSize: "11px", borderRadius: "60px",marginLeft: "0.5rem" }}>{deadline} Dias</button>


                        </>

                        :

                        type == "service"

                            ?
                            <>
                                <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{name}</p>
                                <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{service}</p>
                                <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{mail}</p>
                                <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{phone}</p>

                            </>
                            :


                            <>
                                <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{name}</p>
                                <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{cpf}</p>
                                <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{mail}</p>
                                <p className="textNameTableListManager" style={{ marginLeft: "0.5rem" }}>{phone}</p>

                            </>

                    }

                </div>



                <div onClick={onClick} style={{ width: "100%", height: "44px" }}>

                </div>
            </div>
        </div>

    )

}

export default InputData