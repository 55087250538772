import React, { useState, useEffect, useContext } from 'react'
import FilterProduct from '../../components/FilterProduct'
import Card from '../../components/Card'
import { FilterProductContext } from '../../context/filterProduct'
import columIcon from './images/svg/columIcon.svg'
import orderIcon from './images/svg/orderIcon.svg'
import listIcon from './images/svg/listIcon.svg'
import './styles/style.css'
import { useLocation } from 'react-router-dom';
import FilterProducts from '../../components/FilterProducts'
import Header from '../../components/Header'
import HeaderMobile from '../../components/HeaderMobile'
import HeaderSecondary from '../../components/HeaderSecondary'
import SidebarCart from '../../components/SidebarCart'
import api from '../../services/api'
import { CartContext } from '../../context/cart'
const Accessories = () => {
    const {handleAddToCart } = useContext(CartContext);

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    const { inputSidebar} = useContext(FilterProductContext)

    const [page, setPage] = useState(1); // Estado para a página
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])


    const getAll = async (page, category) => {
        setLoading(true);
        try {
            const response = await api.get(`client/get/all/products/pagination/?page=${page}&category=${category}`);
            if (response.status === 200) {
                setData(prevCategorys => {
                    const newProducts = response.data.products.filter(newProduct =>
                        !prevCategorys.some(existingProduct => existingProduct.id === newProduct.id)
                    );
                    return [...prevCategorys, ...newProducts];
                });
            }
        } catch (error) {
            console.error('Erro ao buscar produtos:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            if (entries.some((entry) => entry.isIntersecting)) {
                setPage((prevPage) => prevPage + 1);
            }
        });

        const sentinela = document.querySelector("#sentinela");
        if (sentinela) intersectionObserver.observe(sentinela);

        return () => {
            if (sentinela) intersectionObserver.unobserve(sentinela);
        };
    }, []);


    useEffect(() => {
        getAll(page, category);
    }, [page]);
    return (

        <>
            <Header />
            <SidebarCart/>
            <HeaderMobile />
            <HeaderSecondary />
            <section className='container_row_products'>
                <section className='container_flex_row_product'>
                <FilterProduct type="product" name="Ordem" id={"5"} image={orderIcon} />            

                    <div class="dropdown">
                        <ul class="dropdown-menu">
                            <li><button class="dropdown-item" type="button">Mais Vendidos</button></li>
                            <li><button class="dropdown-item" type="button">Mais Recentes</button></li>
                            <li><button class="dropdown-item" type="button">Maior Preço</button></li>
                            <li><button class="dropdown-item" type="button">Menor Preço</button></li>

                        </ul>
                    </div>

                    <section className={inputSidebar == false ? 'flex_row_products' : 'flex_row_products_animated'}>

                        {
                            data?.map(item => {
                                return (
                                    <Card onClick={() => handleAddToCart(item)} weight={item.weight} name={item.name} promotion_price={item.promotion_price} product_id={item.product_id} price={item.price} description={item.description} product_image={item.product_image} category_image={item.category_image} />
                                )
                            })
                        }
                        <div id="sentinela"></div>

                    </section>
                </section>

            </section>
        </>


    )
}

export default Accessories