import React from "react";
import styled from "styled-components";

const CardWrapper = styled.div`
    cursor: pointer;
    width:286px;
    height: 286px;
    border-radius:5px;

    img{
        
        object-fit: contain;
        width: 100%;
        height: 100%;
        border-radius:5px;
    }

    @media screen and (max-width:720px) {
        
        width: 115px;
        height: 115px;
    }

`

const CardSlider = ({image}) => {
    return(

        <CardWrapper>
            <img src={image}/>            
        </CardWrapper>
        

    )
}

export default CardSlider