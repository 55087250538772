import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

import CardSlider from '../CardSlider'; // Importando o componente de slide

const CardWrapper = styled.div`

    width: 100%;
    height: 300px;
    margin-top: 2rem;
    @media screen and (max-width:720px){
        height: 120px;
    }

`


export default function HorizontalCarrousel() {
    const images = [
        {
            url: "https://firebasestorage.googleapis.com/v0/b/app-tkg.appspot.com/o/banner%2Fimages%2F0.05045170683021938?alt=media&token=9899e4ec-0fc1-4ae7-973c-8cb833405256"
        },
        {
            url: "https://firebasestorage.googleapis.com/v0/b/app-tkg.appspot.com/o/banner%2Fimages%2F0.05045170683021938?alt=media&token=9899e4ec-0fc1-4ae7-973c-8cb833405256"
        },
        {
            url: "https://firebasestorage.googleapis.com/v0/b/app-tkg.appspot.com/o/banner%2Fimages%2F0.05045170683021938?alt=media&token=9899e4ec-0fc1-4ae7-973c-8cb833405256"
        },
        {
            url: "https://firebasestorage.googleapis.com/v0/b/app-tkg.appspot.com/o/banner%2Fimages%2F0.05045170683021938?alt=media&token=9899e4ec-0fc1-4ae7-973c-8cb833405256"
        },
        {
            url: "https://firebasestorage.googleapis.com/v0/b/app-tkg.appspot.com/o/banner%2Fimages%2F0.05045170683021938?alt=media&token=9899e4ec-0fc1-4ae7-973c-8cb833405256"
        },
        {
            url: "https://firebasestorage.googleapis.com/v0/b/app-tkg.appspot.com/o/banner%2Fimages%2F0.05045170683021938?alt=media&token=9899e4ec-0fc1-4ae7-973c-8cb833405256"
        },
        {
            url: "https://firebasestorage.googleapis.com/v0/b/app-tkg.appspot.com/o/banner%2Fimages%2F0.05045170683021938?alt=media&token=9899e4ec-0fc1-4ae7-973c-8cb833405256"
        },
        {
            url: "https://firebasestorage.googleapis.com/v0/b/app-tkg.appspot.com/o/banner%2Fimages%2F0.05045170683021938?alt=media&token=9899e4ec-0fc1-4ae7-973c-8cb833405256"
        }
        
        
        // Outras URLs...
    ];

    return (
        <CardWrapper >
            <Swiper
                direction="horizontal"
                spaceBetween={40}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: false,
                }}
                breakpoints={{
                    320: { // Tela pequena
                        slidesPerView:3,
                        spaceBetween:60,
                    },
                    640: { // Tela média
                        slidesPerView: 4,
                        spaceBetween: 60,
                    },
                    768: { // Tablet
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    1024: { // Desktop
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                }}
                
                modules={[Autoplay]}
                className="mySwiper"
            >
                {images?.map((item, index) => (
                    <SwiperSlide key={index}>
                        <CardSlider image={item.url} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </CardWrapper>
    );
}
