import React, { createContext, useState,useEffect } from 'react'
import api from '../services/api'
import { ToastContainer,toast } from "react-toastify";
export const FilterContext = createContext({})
function FilterProvider({ children }) {
    const [filterProduct, setFilterProduct] = useState(false)
    const [typeTable, setTypeTable] = useState(1)
    const [dataUsers, setDataUsers] = useState([])
    const [animation, setAnimation] = useState(null)
    const [userSelectPrimary, setUserSelectPrimary] = useState([])
    const [userSelectSecondary, setUserSelectSecondary] = useState([])
    const [dataFilterProducts,setDataFilterProducts] = useState([])
    const [variationImages,setVariationsImage] = useState([])
    const [priceVariation,setPriceVariation] = useState(0)
    const [userSelectVariation,setUserSelectVariation] = useState(0)
    const [userSelectValue,setUserSelectValue] = useState(0)

    const filter = async (type, name) => {
        if (type == 1) {
            if (name == undefined || name == "") {

                


                const response = await api.get("client/get/all/requests/")
                if (response.status == 404) {
                    setDataUsers([])
                    return false
                }
                setDataUsers(response.data)

                setTimeout(() => {
                    setAnimation(false)
                }, [3000])

                return false
            }
        }

        if (type == 2) {
            if (name == undefined || name == "") {
                const response = await api.get("provider/get/all")
                if (response.status == 404) {
                    return false
                }
                setDataUsers(response.data)


                setTimeout(() => {
                    setAnimation(false)
                }, [3000])


                return false

            }
        }

        if (type == 1) {
            const response = await api.get(`panel/get/by/client/name/${name}`)
            if (response.status == 404) {
                setDataUsers([])
                return false
            }
            setDataUsers(response.data)
            return false
        }


        if (type == 2) {
            const response = await api.get(`panel/get/by/provider/name/${name}`)
            if (response.status == 404) {
                setDataUsers([])
                return false
            }
            setDataUsers(response.data)
            return false
        }



    }
    const handleCloseFilterProduct = () => {
        setFilterProduct(false)
    }
    const handleOpenFilterProduct = () => {
        setFilterProduct(true)
    }

    const storageFilter = async () => {

        const url = window.location.pathname
       
        if(url != "/"){
            const response = await api.post("client/get/all/products/filter",{
                engateCategory: localStorage.getItem("@TYPE_ENGATE_FILTER") == "1"  ? "Engate Fixo" : "Engate Removivel",
                model:userSelectPrimary.name,
                category:userSelectSecondary.name,
            })
    
            
            if(response.status == 200){
                localStorage.setItem("@FILTER_DATA",JSON.stringify(response.data))
                setFilterProduct(false)
            }else{
                return toast.error("Nenhum produto encontrado :(")
            }
            window.location.href="/"
        }


        const response = await api.post("client/get/all/products/filter",{
            engateCategory: localStorage.getItem("@TYPE_ENGATE_FILTER"),
            model:userSelectPrimary.model,
            category:userSelectSecondary.name,
        })

        
        if(response.status == 200){
            setDataFilterProducts(response.data)
            localStorage.setItem("@FILTER_DATA",JSON.stringify(response.data))
            setFilterProduct(false)
        }else{
            return toast.error("Nenhum produto encontrado :(")
        }

    }   

    const get = (type) => {
        setTypeTable(type)
    }

    const getLocalProductImages =  (images) => {
        console.log(images)
        setVariationsImage(images);
    }

    const handleSelectVariation =  async (product_id,value,id) =>{

        const response = await api.post(`client/get/images/variation/by/`,{
            product_id:product_id,
            value:value
        })

        console.log(response.data)

        if(response.status == 200){
            setUserSelectVariation(true)
            setVariationsImage(response.data.images)
            setPriceVariation(String(response.data.variation[0].price).replace(",","."))
            setUserSelectValue(id)
            return false
        }
        
        const dataVariation = localStorage.getItem("@PRODUCT_IMAGES");
        const storageData = JSON.parse(dataVariation);
        setVariationsImage(storageData);
        setUserSelectVariation(false)

        
    }

    useEffect(() =>{

        getLocalProductImages()

    },[])


    return (
        <>
        <ToastContainer/>
        <FilterContext.Provider value={{
            get,
            filter,
            handleCloseFilterProduct,
            handleOpenFilterProduct,
            filterProduct,
            dataUsers,
            animation,
            typeTable,
            dataFilterProducts,
            setUserSelectPrimary,
            setUserSelectSecondary,
            userSelectSecondary,
            storageFilter,
            handleSelectVariation,
            setVariationsImage,
            variationImages,
            priceVariation,
            userSelectVariation,
            userSelectValue,
            getLocalProductImages


        }}>
            {children}
        </FilterContext.Provider>
        </>
    )

}

export default FilterProvider