import React, { createContext, useRef, useState } from 'react';

export const FilterProductContext = createContext({});

function FilterProductProvider({ children }) {

    const [inputSidebar, setInputSidebar] = useState(false)
    const [inputColumState, setInputColumState] = useState(false)
    const [inputListState, setInputListState] = useState(false)
    const [isAllFiltersOpen, setIsAllFiltersOpen] = useState(true);

    const handleFilterSidebarButton = (state) => {
        setInputSidebar(!inputSidebar)
    }

    const handleFilterColunButton = (e) => {
        setInputColumState(e)
    }

    const handleListButton = (id) => {
        setInputListState(id)
    }

    const handleToggleAllFilters = () => {
        setIsAllFiltersOpen(!isAllFiltersOpen);
    };


    return (
        <FilterProductContext.Provider value={{
            handleFilterSidebarButton,
            handleFilterColunButton,
            handleListButton,
            inputListState,
            inputSidebar
        }}>
            {children}
        </FilterProductContext.Provider>
    );
}

export default FilterProductProvider;
