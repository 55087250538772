import React from 'react'
import api from '../../services/api';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import 'moment/locale/pt-br'; // Importa a localização em português para o moment
import { toast } from 'react-toastify';
const TableListAdress = ({theadData,tbodyData}) => {
    
    moment.locale('pt-br'); // Define a localização do moment para português

    const handleDelete = async (id) => {
        const response = await api.delete(`/addresses/delete/${id}`)
        if(response.status == 401){
            return toast.error("Você precisa ter pelo menos 1 endereço!",{
                autoClose:1500
            })
        }
        window.location.href="/dashboard/v2/address"
    }

    return (
        <Table bordered hover >
            <thead>
                <tr>
                <th>Cep</th>
                <th>Rua</th>
                <th>Número</th>
                <th className='th_mobile'>Complemento</th>
                <th>Ação</th>

                </tr>
            </thead>
            <tbody>


                {tbodyData?.map((item) => {

                    return(
                        <tr>

                        <td>{item.zipCode}</td>
                        <td>{item.street}</td>
                        <td >{item.number}</td>
                        <td className='th_mobile'>{item.complement}</td>
                        <td style={{cursor:"pointer"}} onClick={() => handleDelete(item.id)}>Excluir </td>

                        </tr>

                    )
                })}
            </tbody>
        </Table>
    )
}

export default TableListAdress