import React, { useState, useEffect } from 'react'
import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'
import HeaderDashboard from '../../components/HeaderDashboard'
import { ToastContainer, toast } from 'react-toastify';
import notFoundIcon from './images/svg/notFoundIcon.svg'
import api from '../../services/api';
import Sidebar from '../../components/Sidebar'
import SidebarMobile from '../../components/SidebarMobile'
import TableListMobileCategorys from '../../components/TableListMobileCategorys';
import TableListSchedules from '../../components/TableListSchedules'

import ThowColumLayoutDashboard from '../../layouts/ThowColumLayoutDashboard'
import { useQuery } from '@tanstack/react-query';


const useGetRequests = () => {

    return useQuery({
        queryFn: async () => {
            const response = await api.get(`client/get/all/requests/`);
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Erro ao buscar produtos');
            }
        },
        keepPreviousData: true,
    });
};

const Dashboard = () => {

    const [user, setUser] = useState()
    const [openSidebar, setOpenSidebar] = useState(false)
    const { data = [], isLoading, isError, error } = useGetRequests();


    useEffect(() => {

        const data = JSON.parse(localStorage.getItem("@USER_DATA"))
        setUser(data)


    }, [])

    return (
        <ThowColumLayoutDashboard

            colum1={<Sidebar />}
            colum2={

                <div className='container_secondary'>
                    <ToastContainer />


                    <div className='container_data'>
                        <div>
                            <HeaderDashboard name={"Meus Pedidos"} />
                            <TableListSchedules data={data} table={"1"} />
                            
                            {/* <div className='not_found_products'>
                            <img src={notFoundIcon}/>
                            </div> */}

                        </div>


                    </div>
                    

                    <div className='container_mobile'>

                        {openSidebar == true

                            ?
                            <div className='sidebar_open_background' onClick={() => setOpenSidebar(!openSidebar)}>
                            </div>
                            :
                            <></>

                        }
                        <HeaderDashboardMobile onClick={() => setOpenSidebar(true)} />
                        <SidebarMobile state={openSidebar} />

                        <div className='header_container_mobile'>
                            {/* <button className='btn_consult_mobile'>Consulta Clínica +</button> */}
                        </div>



                        <div style={{ width: "100%", background: "white", paddingLeft: "1.46rem", overflowY: "hidden" }}>
                            <div style={{ width: "90vw", overflowX: "scroll", overflowY: "hidden", position: "fixed" }}>

                                <div style={{ minWidth: "670px", paddingLeft: "0rem", display: "flex", overflowY: "hidden" }}>
                                    <TableListMobileCategorys type={"category"} />
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            }>

        </ThowColumLayoutDashboard>
    )
}

export default Dashboard