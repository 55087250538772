import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import api from '../services/api';

export const CartContext = createContext({});

function CartProvider({ children }) {
    const [cart, setCart] = useState([]);
    const [price, setPrice] = useState(0);
    const [priceAddress, setPriceAddress] = useState(0);
    const [dateFreight, setDateFreight] = useState('');
    const [priceFreight, setPriceFreight] = useState(0);
    const [cartLength, setCartLength] = useState(0);
    const [stateCart, setStateCart] = useState(false);
    const [userSelectState, setUserSelect] = useState()
    const [cep,setCep] = useState()
    const [userSelectCity, setUserSelectCity] = useState()
    const [street, setStreet] = useState()
    const [number, setNumber] = useState()
    const [complement, setComplement] = useState()


    const handleAddToCart = async (data) => {


        // const itemCart = cart.find(item => item.name === data.name);
        // if (itemCart) {
        //     setStateCart(true);

        //     return false;
        // }

        localStorage.setItem("@LOCAL_CART", JSON.stringify([...cart, { ...data, quantity: 1 }]))
        setCart([...cart, { ...data, quantity: 1 }]);
        
        setStateCart(true);
    };
    const recoveryCart = () => {


        if(localStorage.getItem("@LOCAL_CART")){
            
            const data = JSON.parse(localStorage.getItem("@LOCAL_CART"))
            if (data) {
                setCart(data)
            }
        }



    }
    const handleStateCart = (state) => {
        if(cart.length == 0){
            setStateCart(false);
            return false
        }
        setStateCart(state);
    };
    const handleRemoveItem = (index) => {

        if(cart.length == 0){
            setStateCart(false)
        }

        const local_data_copy = JSON.parse(localStorage.getItem("@LOCAL_CART"))
        const copy = [...cart];
        copy.splice(index, 1);
        setCart(copy);
        local_data_copy.splice(index, 1)
        localStorage.setItem("@LOCAL_CART", JSON.stringify(local_data_copy))

        

    };
    const handleUpdateQuantity = (index, quantity) => {
        if (quantity) {
            const copyLocalCart = JSON.parse(localStorage.getItem("@LOCAL_CART"))
            
            copyLocalCart[index].quantity = quantity
            localStorage.setItem("@LOCAL_CART",JSON.stringify(copyLocalCart))
            const copy = [...cart];
            copy[index].quantity = quantity;
            setCart(copy);
        }
    };
    const getCep = async (cep) => {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        if (response.status == 200) {
            setStreet(response.data.logradouro)
            setUserSelect(response.data.uf)
            setUserSelectCity(response.data.localidade)
        }

    }
    const handleFreight = async (cep) => {

        const reduceWeight = cart.reduce((acc, item) => acc + Number(item.weight) * Number(item.quantity), 0);
                
        const responseApi = await api.post("client/get/freight", {
            cep: String(cep).replace("-", ""),
            weight: reduceWeight
        });

        if (responseApi.status === 200) {
            setDateFreight(responseApi.data.prazo);
            setPriceAddress(responseApi.data.vltotal);
            getCep(String(cep).replace("-", ""))
            setCep(cep)
        }
    };

    useEffect(() => {
        // Calcula o preço total sempre que o carrinho é atualizado
        const total = cart.reduce((acc, item) => acc + Number(item.price) * item.quantity, 0);
        setPrice(total);
        setCartLength(cart.length);

        // Atualiza o cálculo do frete baseado no preço do endereço
        const totalQuantity = cart.reduce((a, item) => a + Number(item.quantity), 0);

        handleFreight(cep)

        
    }, [cart, priceAddress]);

    useEffect(() => {

        recoveryCart()
    }, [])



    return (
        <CartContext.Provider value={{ handleAddToCart, handleStateCart, handleRemoveItem, handleUpdateQuantity, handleFreight, cart, price, cartLength, stateCart, priceFreight, street, userSelectState, userSelectCity, setUserSelect, setUserSelectCity,setNumber,setStreet,number,priceAddress,setCep,cep,setComplement,complement }}>
            {children}
        </CartContext.Provider>
    );
}

export default CartProvider;
