import React from 'react'
import Header from '../../components/Header'
import HeaderSecondary from '../../components/HeaderSecondary'
import ContainerPage from '../../components/Container'
import ContainerAccountLogin from '../../components/ContainerAccountLogin'
import HeaderMobile from '../../components/HeaderMobile'
import SidebarFilter from '../../components/SidebarFilterTest'
const AccountLogin = () => {
    return (

        <ContainerPage>
            <Header />
            <HeaderMobile/>
            <HeaderSecondary />
            <ContainerAccountLogin />
            <SidebarFilter/>
        </ContainerPage>

    )
}

export default AccountLogin