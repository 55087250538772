import React, { useContext, useState } from 'react'
import Container from '../../components/Container'
import Header from '../../components/Header'
import HeaderSecondary from '../../components/HeaderSecondary'
import ContainerProduct from '../../components/ContainerProduct'
import HeaderMobile from '../../components/HeaderMobile'
import { CartContext } from '../../context/cart'
import SidebarCart from '../../components/SidebarCart'
import SidebarFilter from '../../components/SidebarFilterTest';

const Product = () => {

    const {cartLenght } = useContext(CartContext)

    return (
        <Container>
            <SidebarFilter/>
            <Header data={cartLenght} />
            <HeaderMobile />
            <HeaderSecondary />
            <ContainerProduct />
            <SidebarCart state={false} />
        </Container>

    )
}

export default Product