import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppStack from './routes/AppStack';
import CartProvider from './context/cart';
import FilterProvider from './context/filter';
import CarrouselProvider from './context/carrousel';
import SidebarProvider from './context/sidebar';
import FilterProductProvider from './context/filterProduct'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>

    <FilterProductProvider>
    <SidebarProvider>
    <FilterProvider>
    <CarrouselProvider>
    <CartProvider>
    <AppStack />
    </CartProvider>
    </CarrouselProvider>
    </FilterProvider>
    </SidebarProvider>
    </FilterProductProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
