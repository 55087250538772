import React, { useContext, useEffect, useState } from 'react'
import api from '../../services/api'
import { useNavigate } from 'react-router-dom'
import companyIcon from './images/png/companyIcon.png'
import Collapse from '../Collapse'
import InputSidebar from '../InputSidebar'
import { SidebarContext } from '../../context/sidebar'
import './styles/style.css'
import { useLocation } from 'react-router-dom'

const SidebarFilter = ({mobile}) => {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('category_id');

    const navigate = useNavigate()
    const [stateEyeClick, setStateEyeClick] = useState(false)
    const [dataSearch, setDataSearch] = useState([])
    const [categorys, setCategorys] = useState([])
    const { sidebarState, handleSidebar,handleInputSidebar, inputSidebar,category } = useContext(SidebarContext)

    const getProduct = async () => {
        if (inputSidebar == "") {
            setDataSearch([])
            return false
        }
        const response = await api.get(`client/get/product/by/name/${inputSidebar}`)
        if (response.status == 200) {
            setDataSearch(response.data);

        }

    }
    const redirectToProductPage = async (product_id,name,price,description,promotion_price,weight) => {
        setDataSearch([])
        handleInputSidebar("")
        handleSidebar(true)
        localStorage.setItem("@NAME", name);
        localStorage.setItem("@DESCRIPTION", description);
        localStorage.setItem("@PRICE", price);
        localStorage.setItem("@PROMOTIONAL_PRICE", promotion_price);
        localStorage.setItem("@WEIGHT", weight);
        navigate(`/product/?id=${product_id}&category_id=${category}`)

    };
    const getData = async () => {

        if(category == undefined){
            const response = await api.get(`category/sub/${id}`)
            console.log(response.data)
            if (response.status == 200) {
                setCategorys(response.data)
            }
    
            if(response.status == 404){
                setCategorys([])
            }

            return

        }
        const response = await api.get(`category/sub/${category}`)
        if (response.status == 200) {
            setCategorys(response.data)
        }

        if(response.status == 404){
            handleSidebar(false)

            setCategorys([])
        }

    }

    useEffect(() => {

        getData()
    },[])
    useEffect(() => {
        getData()
    }, [category])



    useEffect(() => {

        getProduct()
    }, [inputSidebar])

    // useEffect(() => {
    //     if(mobile == true){
    //         handleSidebar(false)
    //     }else{
    //         handleSidebar(true)

    //     }

    // },[mobile])


    return (

        <aside className={sidebarState ? 'sidebar_control' : 'sidebar_control_close'}>

            <div className='header_sidebar_control'>
                <img src={companyIcon} />

                <svg width="14" onClick={(() => handleSidebar(false))} height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.07268 6.86851L13.469 1.46626C13.804 1.13088 13.804 0.58713 13.469 0.251778C13.134 -0.0836006 12.5909 -0.0836006 12.256 0.251778L6.86023 5.65459L1.46449 0.251778C1.12952 -0.0836006 0.586422 -0.0836006 0.251475 0.251778C-0.0834726 0.587157 -0.0834994 1.13091 0.251475 1.46626L5.64777 6.86851L0.251475 12.2708C-0.0834994 12.6062 -0.0834994 13.1499 0.251475 13.4853C0.586449 13.8206 1.12955 13.8207 1.46449 13.4853L6.86023 8.08243L12.256 13.4853C12.5909 13.8207 13.134 13.8207 13.469 13.4853C13.8039 13.1499 13.8039 12.6061 13.469 12.2708L8.07268 6.86851Z" fill="black" />
                </svg>


            </div>
            <InputSidebar />

            <div className='body_sidebar_control'>



                {dataSearch.length > 0

                    ?
                    dataSearch?.map(item => {
                        return (
                            <div className={stateEyeClick == true ? " card-body card_active" : " card-body"}>
                                <div className='flex_btn_collapse_sub'>

                                    <div onClick={() => redirectToProductPage(item.product_id,item.name,item.price,item.description,item.promotion_price,item.weight)}>
                                        <img  src={item.product_image} />

                                        <p >{item.name}</p>
                                    </div>
                                    <div style={{width:"10px"}}></div>
                                    <svg onClick={() => {setStateEyeClick(true);redirectToProductPage(item.product_id,item.name,item.price,item.description,item.promotion_price,item.weight)}} width="34" height="34" viewBox="0 1 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="1" width="23" height="23" rx="4.5" fill="white" stroke="#D7D7D7" />
                                        <g clip-path="url(#clip0_0_15449)">
                                            <path d="M19.5442 11.3867C18.5748 9.80793 16.3698 7.15918 12.4998 7.15918C8.62985 7.15918 6.42485 9.80793 5.45547 11.3867C5.1558 11.8714 4.99707 12.43 4.99707 12.9998C4.99707 13.5697 5.1558 14.1282 5.45547 14.6129C6.42485 16.1917 8.62985 18.8404 12.4998 18.8404C16.3698 18.8404 18.5748 16.1917 19.5442 14.6129C19.8439 14.1282 20.0026 13.5697 20.0026 12.9998C20.0026 12.43 19.8439 11.8714 19.5442 11.3867V11.3867ZM18.4786 13.9586C17.6461 15.3123 15.7617 17.5904 12.4998 17.5904C9.23797 17.5904 7.3536 15.3123 6.5211 13.9586C6.34306 13.6705 6.24876 13.3385 6.24876 12.9998C6.24876 12.6611 6.34306 12.3292 6.5211 12.0411C7.3536 10.6873 9.23797 8.40918 12.4998 8.40918C15.7617 8.40918 17.6461 10.6848 18.4786 12.0411C18.6566 12.3292 18.7509 12.6611 18.7509 12.9998C18.7509 13.3385 18.6566 13.6705 18.4786 13.9586V13.9586Z" fill="black" />
                                            <path d="M12.5 9.875C11.8819 9.875 11.2777 10.0583 10.7638 10.4017C10.2499 10.745 9.8494 11.2331 9.61288 11.8041C9.37635 12.3751 9.31447 13.0035 9.43505 13.6096C9.55563 14.2158 9.85325 14.7727 10.2903 15.2097C10.7273 15.6467 11.2842 15.9444 11.8903 16.0649C12.4965 16.1855 13.1249 16.1236 13.6959 15.8871C14.2669 15.6506 14.755 15.25 15.0983 14.7361C15.4417 14.2222 15.625 13.6181 15.625 13C15.624 12.1715 15.2944 11.3772 14.7086 10.7914C14.1228 10.2056 13.3285 9.87599 12.5 9.875V9.875ZM12.5 14.875C12.1292 14.875 11.7666 14.765 11.4583 14.559C11.15 14.353 10.9096 14.0601 10.7677 13.7175C10.6258 13.3749 10.5887 12.9979 10.661 12.6342C10.7334 12.2705 10.912 11.9364 11.1742 11.6742C11.4364 11.4119 11.7705 11.2334 12.1342 11.161C12.4979 11.0887 12.8749 11.1258 13.2175 11.2677C13.5601 11.4096 13.853 11.65 14.059 11.9583C14.265 12.2666 14.375 12.6292 14.375 13C14.375 13.4973 14.1775 13.9742 13.8258 14.3258C13.4742 14.6774 12.9973 14.875 12.5 14.875Z" fill="black" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_0_15449">
                                                <rect width="15" height="15" fill="white" transform="translate(5 5.5)" />
                                            </clipPath>
                                        </defs>
                                    </svg>


                                </div>
                            </div>
                        )
                    })

                    :
                    categorys?.map(item => {
                        const count = 0
                        return (
                            <Collapse count={count} image={item.image} id={item.id} name={item.name} product_id={item.product_id} />

                        )
                    })
                }



            </div>


            <div className='end_sidebar_control'>

            </div>



        </aside>

    )
}

export default SidebarFilter