import React, { useEffect, useState, useContext } from 'react';
import trackenIcon from '../../images/png/tracken-api-logotipo-circular.png'
import { useLocation } from 'react-router-dom';
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import deliveryIcon from '../../images/png/entrega-rapida.png';
import Input from '../Input';
import ReactPlayer from 'react-player';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import "draft-js/dist/Draft.css"; // Importe os estilos básicos do Draft.js aqui
import { Routes, useNavigate } from 'react-router-dom';
import CardVariation from '../CardVariation';
import { CartContext } from '../../context/cart';
import Animation from '../Animation'
import { FilterContext } from '../../context/filter';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // Requer um carregador
import { Carousel } from 'react-responsive-carousel';


const ContainerProduct = () => {
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const { variationImages, setVariationsImage,priceVariation, userSelectVariation, userSelectValue, getLocalProductImages } = useContext(FilterContext)
    const { handleAddToCart } = useContext(CartContext)

    const handleTouchStart = (e) => {
        e.stopPropagation();
    };

    const handleTouchMove = (e) => {
        if (e.cancelable) {
            e.stopPropagation();
        }
    };
    const navigate = useNavigate()
    const [animation, setAnimation] = useState(true)
    const [zipCode, setZipCode] = useState()
    const [dataSheet, setDataSheet] = useState([])
    const [typeDelivery, setTypeDelivery] = useState()
    const [dateFreigh, setDateFreigh] = useState()
    const [priceFreigh, setPriceFreigh] = useState()
    const [freight, setFreight] = useState(false)
    const [btnState, setBtnState] = useState(true)
    const [price, setPrice] = useState();

    const [promotionalPrice, setPromotionalPrice] = useState();
    const [variationdata, setVariationData] = useState([])
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty()); // Estado inicial vazio do Editor
    const [selectVariation, setSelectVariation] = useState()

    const get = async () => {
        const response = await api.get(`client/get/product/by/id/${id}`)

        if(response.status == 200){
            localStorage.setItem("@NAME",response.data[0].name)
            localStorage.setItem("@PRICE",response.data[0].price)
            localStorage.setItem("@PROMOTIONAL_PRICE",response.data[0].promotion_price)
        }


    }
    const CustomArrow = ({ onClick, direction }) => (
        <button
            type="button"
            onClick={onClick}
            style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                color: 'red', // Define a cor desejada aqui
                fontSize: '30px', // Tamanho da seta
                position: 'absolute',
                top: '50%',
                zIndex: "9999",
                transform: 'translateY(-50%)',
                [direction === 'left' ? 'left' : 'right']: '10px',
            }}
        >

            {direction === "left"

                ?
                <svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2_25)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.93 1.485C15.195 0.749996 14.01 0.749996 13.275 1.485L0.809996 13.95C0.224996 14.535 0.224996 15.48 0.809996 16.065L13.275 28.53C14.01 29.265 15.195 29.265 15.93 28.53C16.665 27.795 16.665 26.61 15.93 25.875L5.07 15L15.945 4.125C16.665 3.405 16.665 2.205 15.93 1.485Z" fill="#1D1D1D" />
                    </g>
                    <defs>
                        <clipPath id="clip0_2_25">
                            <rect width="16.5" height="30" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

                :

                <svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2_3)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.570001 28.515C1.305 29.25 2.49 29.25 3.225 28.515L15.69 16.05C16.275 15.465 16.275 14.52 15.69 13.935L3.225 1.47C2.49 0.734997 1.305 0.734997 0.570001 1.47C-0.164999 2.205 -0.164999 3.39 0.570001 4.125L11.43 15L0.555 25.875C-0.165 26.595 -0.164999 27.795 0.570001 28.515Z" fill="#1D1D1D" />
                    </g>
                    <defs>
                        <clipPath id="clip0_2_3">
                            <rect width="16.5" height="30" fill="white" />
                        </clipPath>
                    </defs>
                </svg>


            }

        </button>
    );
    const YoutubeSlide = ({ url }) => (
        <ReactPlayer width="100%" height={"490px"} url={url} />
    );
    const ImageSlide = ({ src }) => (
        <img src={src} className='image_carrousel_product' alt="Image" />
    );
    const getThumbnail = (url) => {
        if (url.includes('youtube')) {
            const videoId = url.split('/').pop();
            return `https://img.youtube.com/vi/${videoId}/default.jpg`;
        }
        // Se não for um vídeo do YouTube, retorna o próprio URL
        return url;
    };
    const customRenderItem = (url) => {
        if (url.includes('youtube')) {
            return <YoutubeSlide url={url} />;
        } else {
            return <ImageSlide src={url} />;
        }
    };
    const customRenderThumb = (urls) =>
        urls.map((url, index) => (
            <img key={`thumb-${index}`} src={getThumbnail(url)} alt={`Thumbnail ${index}`} />
        ));
    const getVariationData = async () => {

        const response = await api.get(`client/get/all/variations/${id}`)
        console.log(response.data)
        if (response.status == 200) {
            setVariationData(response.data)
        }


    }
    const getData = async () => {
        try {

            const datasheet = await api.get(`/client/get/datasheet/${id}`)
            const data = datasheet.data[0].datasheet

            if (data) {
                setDataSheet(data)

            }


            const descriptionFromStorage = localStorage.getItem("@DESCRIPTION");

            if (descriptionFromStorage) {
                const contentState = convertFromRaw(JSON.parse(descriptionFromStorage));
                setEditorState(EditorState.createWithContent(contentState));
            }

            setPrice(localStorage.getItem("@PRICE").replace(",", "."));
            setPromotionalPrice(localStorage.getItem("@PROMOTIONAL_PRICE").replace(",", "."));

        } catch (error) {
            console.error("Erro ao obter dados do localStorage:", error);
            // Lidar com o erro conforme necessário
        }
    };
    const getInfoProduct = async () => {


        const variation = await api.get(`client/get/variation/${id}`)

        if (variation.status == 200) {
            localStorage.setItem("@PRODUCT_VARIATIONS", JSON.stringify(variation.data));
            localStorage.setItem("@PRODUCT_VARIATIONS_IMAGES", JSON.stringify(variation.data.variations_images));
        }

        const response = await api.get(`client/get/all/images/product/${id}`);
        if (response.status === 200) {
            getLocalProductImages(response.data)
        }


    }
    const handlePaymentBtn = async () => {

        if (zipCode == undefined) {
            return toast.error("Informe um cep!")
        }

        if (userSelectVariation == true) {
            const response = await api.get(`client/get/user/variation/${userSelectValue}`)
            handleAddToCart(response.data[0])
            navigate("/payment")
        }

        const product = await api.get(`client/get/product/by/id/${id}`)
        if (product.status == 200) {
            handleAddToCart(product.data[0])
            navigate("/payment")
        }

    }
    const handleFreight = async () => {

        try {


            if (String(zipCode).length == 9) {

                const weight = localStorage.getItem("@WEIGHT");
                if (!weight) {
                    console.error("Weight not found in localStorage");
                    return;
                }


                const response = await api.post("client/get/freight", {
                    cep: String(zipCode).replace("-", ""),
                    weight: weight,
                    product_id: id,
                });


                if (response.status == 401 || response.status == 500) {
                    setBtnState(false)
                    setDateFreigh()
                    return toast.error("Endereço não disponivel", {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                }


                if (response.status === 200) {
                    setBtnState(true)
                    localStorage.setItem("@ZIP_CODE", zipCode);

                    if (response.data.coust_delivery == "") {
                        console.log(response.data)
                        setDateFreigh(response.data.prazo[0].prazo + Number(response.data.manufacture));
                        setPriceFreigh(response.data.prazo[0].vltotal);
                        setTypeDelivery(response.data.type_delivery)

                        return
                    }

                    setDateFreigh(Number(response.data.manufacture) + Number(response.data.adicional_days));
                    setPriceFreigh(response.data.vltotal || response.data.coust_delivery);
                    setTypeDelivery(response.data.type_delivery)
                } else {
                    console.error("Unexpected response status:", response.status);
                }

            }


        } catch (error) {
            if (error.response) {
                // A resposta foi recebida e o servidor respondeu com um status diferente de 2xx
                console.error("Error response:", error.response.data);
            } else if (error.request) {
                // A requisição foi feita, mas não houve resposta
                console.error("No response received:", error.request);
            } else {
                // Alguma outra coisa causou o erro
                console.error("Error:", error.message);
            }
        }

    };

    useEffect(() => {

        handleFreight()
    }, [zipCode]);


    useEffect(() => {
        window.scrollTo(0, 0);

        getVariationData()
        getData();
    }, []);

    useEffect(() => {
        get()
        getData()
    },[])
    useEffect(() => {
        getInfoProduct()
    }, [id])


    setTimeout(() => {

        setAnimation(false)
    }, [1500])




    return (
        <div>
            {animation == true

                ?
                <div className='container_animation_pages'>

                    <div>
                        <Animation />
                    </div>
                </div>
                :
                <></>
            }



            <ToastContainer />
            <div className='container_product'>
                <div className='container_product_row'>
                    <Carousel
                        showStatus={false}
                        showIndicators={false}
                        stopOnHover
                        
                        selectedItem={0}
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                                <CustomArrow onClick={onClickHandler} direction="left" />
                            )
                        }
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                                <CustomArrow onClick={onClickHandler} direction="right" />
                            )
                        }
                    >
                        {variationImages?.map((item, index) => (
                            <div
                                className='image_product_row'
                                key={index}
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                            >
                                {customRenderItem(item.image)}
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div>
                    {/* Restante do conteúdo do produto */}
                </div>

                <div className='container_texts'>

                    {localStorage.getItem("@NAME") 
                    
                    ?
                    <p className='textPrimaryContainerProduct'>{localStorage.getItem("@NAME")}</p>                    
                    :
                    <></>
                    }
                    <p className='textSecondaryContainerProduct'>Disponibilidade: {localStorage.getItem("@TYPE_STOCK")}</p>
                    {localStorage.getItem("@TYPE_STOCK") == "Pronta entrega" 
                    
                    ?
                    <p className='textSecondaryContainerProduct'>{localStorage.getItem("@QTD_STOCK")} Peças disponiveis</p>
                    :
                    <></>
                    }

                    {localStorage.getItem("@PRICE") 
                    
                    ?
                    <>
                    <p className='textPriceContainerProductPromotional'><s>De R$ {Number((localStorage.getItem("@PRICE").replace(",", "."))).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 })}</s></p>
                    <p className='textPriceContainerProduct'>Por R$ {priceVariation != 0 ? Number(localStorage.getItem("@PROMOTIONAL_PRICE").replace(",", ".")).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 }) : Number(localStorage.getItem("@PROMOTIONAL_PRICE").replace(",", ".")).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 })}</p>

                    </>
                    :
                    <></>
                    }


                    <div className='container_frete'>

                        <img src={deliveryIcon} alt="Ícone de Entrega" />
                        <p>CALCULAR PRAZOS E PREÇOS</p>

                        <div className='row_frete'>
                            <Input onChange={(e) => setZipCode(e.target.value)} placeholder={"00000-000"} mask={"99999-999"} />


                            <div className='box_variations'>

                                <div className='row_box_variations'>

{/*                                     
                                {variationdata

                                    .map((item) => {
                                        return <CardVariation onClick={() => setSelectVariation(item.id)} selected={selectVariation} product_id={item.product_id} key={item.name} id={item.id} name={item.name} value={item.value} />;
                                    })} */}
                            </div>
                            </div>


                        </div>



                        {dateFreigh != undefined

                            ?

                            <div className='box_freight'>

                                <div className="flex_box_freight">

                                    <p>Frete Grátis</p>
                                    {typeDelivery == "1"
                                        ?
                                        <img src={trackenIcon} />
                                        :
                                        <img src={"https://jadlogrmc.com.br/wp-content/uploads/2021/03/jadlog-logo.png"} />}
                                </div>

                                <div className='container_data_freight'>
                                    <p><s>R$ {Number(priceFreigh).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</s></p>
                                    <p>{dateFreigh} dias úteis</p>
                                </div>

                            </div>
                            :
                            <></>
                        }

                        {btnState == true

                            ?

                            <button className='btn_sale' onClick={() => handlePaymentBtn()}>
                                {freight == true

                                    ?
                                    <div className='animation_btn_sale'>
                                        <Animation />
                                    </div>
                                    :
                                    "Comprar"

                                }

                            </button>
                            :
                            <></>

                        }

                    </div>



                </div>
            </div>

            <div className='description_product'>
                <p className='text_primary_description_product'>DETALHES DO PRODUTO</p>

                {/* Editor Draft.js */}
                <Editor
                    readOnly={true}
                    editorState={editorState}
                    onChange={() => <></>}
                />
            </div>


            <div className='container_datasheet'>
                <p>Ficha Técnica</p>
                <table className="tabela-dados">
                    <tbody>
                        {dataSheet.map((item, index) => (
                            <tr key={index} className={index % 1 === 0 ? 'linha-cinza' : 'linha-branca'}>
                                <td><strong>{item.name}</strong></td>
                                <td>{item.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default ContainerProduct;
