import React,{useContext} from 'react'
import { CartContext } from '../../context/cart'

const TableMobile = ({name,price,image,variation_image}) => {
    const { handleStateCart} = useContext(CartContext)
    return(

        <div className='table_mobile'>

            <div className='header_table_mobile'>
            <img src={variation_image || image}/>        
            </div>
            
            <div className='body_table_mobile'>
            <p>{name}</p>
            <p>R$ {price}</p>
            <button onClick={() => handleStateCart(true)}>Detalhes</button>
            </div>

        </div>

    )
}

export default TableMobile