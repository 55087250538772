import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import Animation from '../../components/Animation';

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkTokenValidity = async () => {
      const token = localStorage.getItem("@USER_TOKEN");
      if (!token) {
        setIsAuthenticated(false);
        setLoading(false);
        return;
      }

      try {
        const response = await api.get(`client/get/by/id/`);
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          localStorage.removeItem("@USER_TOKEN");
          setIsAuthenticated(false);
        }
      } catch (error) {
        localStorage.removeItem("@USER_TOKEN");
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkTokenValidity();
  }, []);

  useEffect(() => {
    if (!loading && isAuthenticated) {
      navigate('/payment/product');
    }
  }, [loading, isAuthenticated, navigate]);

  if (loading) {
    return (

      <div className='container_animation_pages'>
        <div>
          <Animation />
        </div>
      </div>

    );  }

  return !isAuthenticated ? children : null;
};

export default PrivateRoute;
