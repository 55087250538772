import React,{useState} from 'react'
import styled from 'styled-components'
import addressIcon from '../../images/svg/addressIcon.svg'
import checkAdressIcon from '../../images/svg/checkAdressIcon.svg'

const CardWrapper = styled.section`
    
    display:flex;
    flex-direction: column;
    align-items: center;
`

const Card = styled.div`

    position: relative;
    cursor: pointer;
    margin-top: 2rem;
    display: flex;
    align-items: center;

    p{
        font-size: 1.1rem;
        height: 15px;

        margin-left: 0.9rem;
    }

    .check_image{
        left: -35px;
        position: absolute;
    }

    @media screen and (max-width:720px) {
        margin-top: 1.7rem;
        p{
        
        max-width:90%;
        font-size: 0.9rem;
        height: 5px;
        margin-left: 0.9rem;
        }

        .address_image{
            width: 15px;
            height: 15px;
        }
        
    .check_image{
        left: -25px;
        width: 15px;
        position: absolute
    }

    }

`


const Address = ({checkId,id,onClick,street,number,city}) => {
    return(

        <CardWrapper>

            <Card onClick={onClick}>
                {checkId == id ? <img className='check_image' src={checkAdressIcon}/> : <></>}
                <img src={addressIcon} className='address_image'/>
                <p>{street}  {number} {city}</p>
            </Card>
        

        </CardWrapper>

    )
}

export default Address