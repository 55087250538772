import React from 'react'
import InputMask from 'react-input-mask';

const InputAccount = ({name,classInput,containerInput,onChange,onBlur,mask,id,required,type,typeInput,value,option}) => {
    return(
        type == "select" 
        
        ?
        <div className={classInput != undefined ? classInput : 'container_input_account'}>
        <select onChange={onChange} required={required} className='input_account'>

        {value != undefined 
        
        ?
        <option>{value}</option>
        :
        <option value={""}></option>

        }
        {option 
        ?

        option.map(item => {
            return(
                <option value={item.sigla}>{item.nome}</option>
            )
        })

        :
        <></>
        }

        </select>
        <label htmlFor={id}>{name}</label>
        </div>
        :

        <div className={containerInput != undefined ? 'container_input_account_login' :'container_input_account'}>
        <InputMask  required={required} value={value} type={typeInput} name='input_account' id={id} onChange={onChange} onBlur={onBlur} className={classInput != undefined ? classInput : 'input_account'} mask={mask} />
        <label htmlFor={id}>{name}</label>
        </div>
    )
}

export default InputAccount