import React, { useContext } from "react";
import { SidebarContext } from '../../context/sidebar'
const InputSidebar = ({ onChange }) => {

    const { handleInputSidebar } = useContext(SidebarContext)

    return (

        <div className="input_sidebar">

            <input placeholder="Pesquise aqui ex: Engate byd" onChange={(e) => handleInputSidebar(e.target.value)} />
            <svg width="30" height="30" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="38" height="38" rx="11.5" fill="white" />
                <rect x="0.5" y="0.5" width="38" height="38" rx="11.5" stroke="#E8E8E8" />
                <path d="M19 10C23.968 10 28 14.032 28 19C28 23.968 23.968 28 19 28C14.032 28 10 23.968 10 19C10 14.032 14.032 10 19 10ZM19 26C22.867 26 26 22.867 26 19C26 15.132 22.867 12 19 12C15.132 12 12 15.132 12 19C12 22.867 15.132 26 19 26ZM27.485 26.071L30.314 28.899L28.899 30.314L26.071 27.485L27.485 26.071Z" fill="#020066" />
            </svg>

        </div>

    )
}

export default InputSidebar