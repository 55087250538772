import React, { useState, useContext, useEffect } from 'react'
import { FilterProductContext } from '../../context/filterProduct'

import orderIconCheck from '../ContainerRowProducts/images/svg/orderIconCheck.svg'
import './styles/style.css'
const FilterProduct = ({ name, type, image, id, onClick }) => {

    const { handleFilterSidebarButton, handleListButton, inputListState } = useContext(FilterProductContext)
    const [filterState, setFilterState] = useState(true);
    const [filterProduct, setFilterProduct] = useState(2)

    return (

        type == "product"

            ?
            <aside type="button" data-bs-toggle={id == "5" ? `dropdown` : ''} aria-expanded="false" onClick={() => { setFilterProduct(id); handleListButton(id) }} className={'filter_product_horizontal'}>

                <p>{name}</p>


                <img src={orderIconCheck} />


            </aside>
            :

            <aside onClick={() => { setFilterState(!filterState); handleFilterSidebarButton() }} className={filterState == true ? `filter_product` : `filter_product_close`}>
                <p>Filtrar</p>

                {filterState == true

                    ?
                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M28.1691 13.7434C27.9941 13.7228 27.8174 13.7228 27.6425 13.7434L18.1231 16.2075C17.3653 16.3954 16.7736 16.9871 16.5857 17.7449L14.1848 27.3486C14.0265 27.9878 14.1763 28.6644 14.5897 29.177C15.0031 29.6897 15.6326 29.9794 16.2909 29.9601C16.4658 29.9807 16.6425 29.9807 16.8174 29.9601L26.4421 27.496C27.1999 27.3081 27.7916 26.7164 27.9795 25.9586L30.3804 16.3549C30.5388 15.7157 30.3889 15.0391 29.9755 14.5265C29.5621 14.0138 28.9327 13.7241 28.2744 13.7434H28.1691ZM25.8524 18.1451L24.3781 24.0842L18.439 25.5584L19.9133 19.6193L25.8524 18.1451Z" fill="black" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.6948 0.538422H17.7651C8.45995 0.538422 0.916626 8.08175 0.916626 17.3869V26.3166C0.916626 35.6218 8.45995 43.1651 17.7651 43.1651H26.6948C36 43.1651 43.5433 35.6218 43.5433 26.3166V17.3869C43.5433 8.08175 36 0.538422 26.6948 0.538422ZM17.7651 40.006C10.2095 39.9944 4.08732 33.8722 4.07572 26.3166V17.3869C4.08732 9.83127 10.2095 3.70911 17.7651 3.69751H26.6948C34.2504 3.70911 40.3726 9.83127 40.3842 17.3869V26.3166C40.3726 33.8722 34.2504 39.9944 26.6948 40.006H17.7651Z" fill="black" />
                    </svg>
                    :
                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M28.1691 13.7434C27.9941 13.7228 27.8174 13.7228 27.6425 13.7434L18.1231 16.2075C17.3653 16.3954 16.7736 16.9871 16.5857 17.7449L14.1848 27.3486C14.0265 27.9878 14.1763 28.6644 14.5897 29.177C15.0031 29.6897 15.6326 29.9794 16.2909 29.9601C16.4658 29.9807 16.6425 29.9807 16.8174 29.9601L26.4421 27.496C27.1999 27.3081 27.7916 26.7164 27.9795 25.9586L30.3804 16.3549C30.5388 15.7157 30.3889 15.0391 29.9755 14.5265C29.5621 14.0138 28.9327 13.7241 28.2744 13.7434H28.1691ZM25.8524 18.1451L24.3781 24.0842L18.439 25.5584L19.9133 19.6193L25.8524 18.1451Z" fill="#0c5dd8" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.6948 0.538422H17.7651C8.45995 0.538422 0.916626 8.08175 0.916626 17.3869V26.3166C0.916626 35.6218 8.45995 43.1651 17.7651 43.1651H26.6948C36 43.1651 43.5433 35.6218 43.5433 26.3166V17.3869C43.5433 8.08175 36 0.538422 26.6948 0.538422ZM17.7651 40.006C10.2095 39.9944 4.08732 33.8722 4.07572 26.3166V17.3869C4.08732 9.83127 10.2095 3.70911 17.7651 3.69751H26.6948C34.2504 3.70911 40.3726 9.83127 40.3842 17.3869V26.3166C40.3726 33.8722 34.2504 39.9944 26.6948 40.006H17.7651Z" fill="#0c5dd8" />
                    </svg>
                }


            </aside>

    )
}

export default FilterProduct