import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import InputAccount from '../InputAccount'
import Animation from '../Animation'
import { useNavigate } from 'react-router-dom'
import api from '../../services/api'
import validator from 'email-validator';

import axios from 'axios'
const ContainerAccountLogin = ({ route }) => {

    const navigate = useNavigate()

    const [name, setName] = useState()
    const [mail, setMail] = useState()
    const [cpf, setCpf] = useState()
    const [phone, setPhone] = useState()
    const [cep, setCep] = useState()
    const [street, setStreet] = useState()
    const [state, setState] = useState()
    const [city, setCity] = useState()
    const [number, setNumber] = useState()
    const [complement, setComplement] = useState()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [animationButton, setAnimationButton] = useState(false)
    const [animationData, setAnimationData] = useState(false)
    const [brazilianStates, setBrazilianStates] = useState([])
    const [brazilianCitys, setBrazilianCitys] = useState([])
    const [userSelectState, setUserSelect] = useState()
    const [userSelectCity, setUserSelectCity] = useState()


    const storage = async () => {

        const mailIsValid = validator.validate(mail)

        if(mailIsValid == false){
            return toast.error("Email inválido")
        }

        if (!mail) {
            return toast.error("Informe um email")
        }


        if (!password) {
            return toast.error("Informe uma senha")
        }


        setAnimationButton(true)

        const response = await api.post("session/storage/app", {
            "mail":mail,
            "password":password
        })

        
        if(response.status == 401){

            setTimeout(() => {
                setAnimationButton(false)
            },[1000])


            return toast.error("Email ou senha inválidos!")

        }

        if (response.status == 200) {
  
            localStorage.setItem('@USER_TOKEN', response.data.token)
            localStorage.setItem("@0e63f110-145a-451e-8f8f-3404b15220c5",response.data.user.name)
            localStorage.setItem("@a951ebde-958e-4ad5-8acd-206a506ae580",response.data.user.image)
            localStorage.setItem('@USER_MAIL', mail)
            localStorage.setItem("@USER_DATA",JSON.stringify(response.data.user))


            if(route == "payment/login"){
                
                window.location.href="/payment/product"
                return false
            }

            setTimeout(() => {
                setAnimationButton(false)
                navigate("/dashboard/v2")
            },[1000])

            return false

        }


        

    }
    useEffect(() => {
        const getBrazilianStates = async () => {
            const response = await axios.get(
                "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
            );

            setBrazilianStates(response.data);
        };

        getBrazilianStates();
    }, []);

    useEffect(() => {
        const getCitys = async () => {
            const response = await axios.get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${userSelectState}/distritos`
            );


            setBrazilianCitys(response.data)
        }
        getCitys()

    }, [userSelectState])

    const handleClickNotHaveAccount = () => {
        
        if(route == "payment/login"){
                
            window.location.href="/payment/identification"
            return false
        }
        navigate("/account/register")
    }




    return (

        <div className='container_account_login'>
            <ToastContainer />
            <p className='textSecondary_account'>Dados de acesso</p>

            <InputAccount containerInput={"container_input_account_login"} classInput="input_account_login" required={true} onChange={(e) => setMail(e.target.value)} name={"Email "} id={1} />
            <InputAccount containerInput={"container_input_account_login"} classInput="input_account_login" required={true} typeInput={"password"} onChange={(e) => setPassword(e.target.value)} name={"Senha *"} id={2} />
            <p className='textCreateAccount' onClick={() => handleClickNotHaveAccount()}>Não possui uma conta?</p>


            {animationButton ? (
                <button>
                    <div className='lottie_animation_button'>
                        <Animation />

                    </div>
                </button>
            ) : (
                <button onClick={() => storage()}>
                    Login
                </button>
            )}

            <div style={{ height: "1rem" }}></div>



        </div>


    )
}

export default ContainerAccountLogin