import React, { createContext, useEffect, useRef, useState } from 'react';

export const SidebarContext = createContext({});

function SidebarProvider({ children }) {

    const [inputSidebar,setInputSidebar] = useState(true)
    const [sidebarState,setSidebarState] = useState(false)
    const [category,setCategory] = useState()

    const handleSidebar = (state,category) => {
        setSidebarState(state)
        setCategory(category)
    }

    const handleInputSidebar = (e) => {
        setInputSidebar(e)
    }



    return (
        <SidebarContext.Provider value={{ handleSidebar,handleInputSidebar, sidebarState,inputSidebar,category}}>
            {children}
        </SidebarContext.Provider>
    );
}

export default SidebarProvider;
