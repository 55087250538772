import React, { useRef, useEffect, useState,useContext } from 'react'
import { CarrouselContext } from '../../context/carrousel'
const CardCarrousel = ({ name, data, key, children }) => {
    const {page,setPage,setSession} = useContext(CarrouselContext)
    const [view, setView] = useState(false)
    const carrouselRef = useRef(null);


    useEffect(() => {

        if (data) {
            data?.map(item => {
                if (item.sessionName == name) {
                    setView(true)
                }
            })
        }

    }, [name, data, view])

    const handleRightClick = () => {
        if (carrouselRef.current) {
            setSession(name)
            setPage(page+1)
            carrouselRef.current.scrollLeft += carrouselRef.current.offsetWidth;
        }
    };

    const handleLeftClick = () => {
        if (carrouselRef.current) {
            setPage(page-1)
            carrouselRef.current.scrollLeft -= carrouselRef.current.offsetWidth;
        }
    };

    return (

        view == false

        ?
        <></>

        :
            <div className='control_carrousel'>

                <div className='flex_buttons_carrousel'>
                    <button onClick={() => handleLeftClick()} className='right_btn_carrousel'>
                        <svg width="35" height="35" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="24" height="24" stroke="#E1E1E1" />
                            <path d="M10.0227 11.2852L13.7414 7.56641C14.0148 7.32031 14.425 7.32031 14.6711 7.56641L15.3 8.19531C15.5461 8.44141 15.5461 8.87891 15.3 9.125L12.6477 11.75L15.3 14.4023C15.5461 14.6484 15.5461 15.0586 15.3 15.332L14.6711 15.9336C14.425 16.207 14.0148 16.207 13.7414 15.9336L10.0227 12.2148C9.77656 11.9688 9.77656 11.5586 10.0227 11.2852Z" fill="black" />
                        </svg>


                    </button>

                    <button onClick={() => handleRightClick()} className='left_btn_carrousel'>
                        <svg width="35" height="35" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="24" height="24" stroke="#E1E1E1" />
                            <path d="M15.3 12.2148L11.5812 15.9336C11.3078 16.207 10.8977 16.207 10.6516 15.9336L10.0227 15.332C9.77656 15.0586 9.77656 14.6484 10.0227 14.4023L12.675 11.7773L10.0227 9.125C9.77656 8.87891 9.77656 8.46875 10.0227 8.19531L10.6516 7.56641C10.8977 7.32031 11.3078 7.32031 11.5812 7.56641L15.3 11.2852C15.5461 11.5586 15.5461 11.9688 15.3 12.2148Z" fill="black" />
                        </svg>

                    </button>
                </div>

                <div ref={carrouselRef} className='container_card_carrousel'>
                    {children}
                </div>



            </div>

    )
}

export default CardCarrousel