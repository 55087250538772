import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { CartContext } from '../../context/cart'
import { useNavigate } from 'react-router-dom'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import InputCupom from '../InputCupom'
import TableMobile from '../TableMobile'

const CardWrapper = styled.div`
    display: none;
    margin-top: 0.9rem;
    min-height: 190px;
    overflow-y: auto;
    margin-bottom: 0rem;

    @media screen and (max-width:720px) {
    display: block;
    margin-top: 0.9rem;
    height: 290px;
    overflow-y: auto;
    margin-bottom: 0rem;
        
    }

`

const ContainerPayment = () => {


    const navigate = useNavigate()

    const { cart, price, cep, setCep, handleStateCart, handleFreight, priceFreight, priceAddress, street, userSelectState, userSelectCity, setUserSelectCity, setUserSelect, setNumber, setStreet, number, setComplement } = useContext(CartContext)

    const [brazilianStates, setBrazilianStates] = useState([])
    const [brazilianCitys, setBrazilianCitys] = useState([])

    const handleBtnBuy = () => {
        navigate("/")
    }

    useEffect(() => {

        handleStateCart(false)
    }, [])

    const handleBtnContinue = () => {

        navigate("/payment/identification")

    }

    useEffect(() => {
        const getBrazilianStates = async () => {
            const response = await axios.get(
                "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
            );

            setBrazilianStates(response.data);
        };

        getBrazilianStates();
    }, []);

    useEffect(() => {
        const getCitys = async () => {
            const response = await axios.get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${userSelectState}/distritos`
            );


            setBrazilianCitys(response.data)
        }
        getCitys()

    }, [userSelectState])



    return (

        <div className='container_payment'>

            <ToastContainer style={{ zIndex: 999999 }} />
            <div className='box_container_payment'>
                <div className='container_primary_payment'>
                    <p>Carrinho de produtos</p>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Imagem</th>
                                <th scope="col">Produto</th>
                                <th scope="col">Preço</th>
                                <th scope="col">Detalhes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cart?.map(item => {

                                return (

                                    <tr>
                                        <th className='image_table_th'><img src={item.product_image || item.image} className='image_table_payment' /></th>
                                        <th scope="row">{item.product_name ? `${item.product_name} ${item.value}` : item.name}</th>
                                        <th scope="row">VALOR NO CARTÃO  R$ {Number(item.price).toLocaleString("pt-BR")} OU R$ {Number(item.price).toLocaleString("pt-BR")} EM ATÉ 12X SEM JUROS</th>
                                        <td><button className='update_btn_table_cart' onClick={() => handleStateCart(true)}>Editar</button></td>
                                    </tr>

                                )
                            })}

                        </tbody>
                    </table>
                </div>
                <div className='container_primary_payment'>


                    <CardWrapper>

                        {cart?.map(item => {
                            return (

                                <TableMobile name={item.name} price={Number(item.price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })} image={item.product_image} variation_image={item.image} />

                            )
                        })}
                    </CardWrapper>

                </div>


            </div>


            <div className='container_secondary_payment'>

                <InputCupom name={"Código Cupom"} required={true} />
                <div className='flex_container_secondary_payment'>
                    <p className='text_primary_flex_secondary_payment'>Subtotal</p>
                    <p className='text_secondary_flex_secondary_payment'>{(price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </p>
                </div>



                <div className='flex_container_secondary_payment'>
                    <p className='text_primary_flex_secondary_payment'>Total</p>
                    <p className='text_secondary_flex_secondary_payment'>{(price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </p>
                </div>
                {localStorage.getItem("@LOCAL_CART") == "[]" 
                
                ?
                <></>                
                :
                <button className='btn_primary_payment' onClick={() => handleBtnContinue()}>Continuar</button>

                }
                <button className='btn_secondary_payment' onClick={() => handleBtnBuy()}>Continuar comprando</button>

            </div>

        </div>

    )
}

export default ContainerPayment