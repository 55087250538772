import firebase from "firebase/compat/app"
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyDQsR181eDLzmIgB2MUOrE-deAYOTmtLGc",
    authDomain: "app-tkg.firebaseapp.com",
    projectId: "app-tkg",
    storageBucket: "app-tkg.appspot.com",
    messagingSenderId: "192606705987",
    appId: "1:192606705987:web:bd037538005412340fb7f6",
    measurementId: "G-9SE16NE7F6"
};


// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);


export default app