import React, { useEffect, useState } from 'react'
import HeaderDashboardMobile from '../../../components/HeaderDashboardMobile'
import HeaderDashboard from '../../../components/HeaderDashboard'
import Sidebar from '../../../components/Sidebar'
import SidebarMobile from '../../../components/SidebarMobile'
import TableListSchedules from '../../../components/TableListSchedules'
import TableListMobileCategorys from '../../../components/TableListMobileCategorys'
import ThowColumLayoutDashboard from '../../../layouts/ThowColumLayoutDashboard'
import InputModal from '../../../components/InputModal'
import { BigHead } from '@bigheads/core'

const Account = () => {
    const [openSidebar, setOpenSidebar] = useState(false)
    const [user,setUser] = useState() 
    useEffect(() => {

        const data = JSON.parse(localStorage.getItem("@USER_DATA"))
        setUser(data)


    },[])

    return (
        <ThowColumLayoutDashboard

            colum1={<Sidebar />}
            colum2={

                <div className='container_secondary'>

                    <div className='container_data'>
                        <div>
                            <HeaderDashboard name={"Meu Perfil"} />

                            <div className='container_profile'>

                                <div className='box_profile'>

                                    <div>
                                        <BigHead
                                            width={147}
                                            accessory="shades"
                                            body="chest"
                                            circleColor="blue"
                                            clothing="tankTop"
                                            clothingColor="black"
                                            eyebrows="angry"
                                            eyes="wink"
                                            facialHair="mediumBeard"
                                            graphic="vue"
                                            hair="short"
                                            hairColor="black"
                                            hat="none"
                                            hatColor="green"
                                            lashes="false"
                                            lipColor="purple"
                                            mask="true"
                                            faceMask="true"
                                            mouth="open"
                                            skinTone="brown"
                                        />
                                    </div>

                                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1.06152" y="0.50769" width="32.4283" height="32.2024" rx="16.1012" fill="black" stroke="#D7D7D7" />
                                        <path d="M21.4576 12.4653C21.0328 12.4653 20.6428 12.2232 20.4478 11.8497L19.9464 10.8467C19.626 10.2172 18.7903 9.69844 18.08 9.69844H16.4852C15.7678 9.69844 14.9321 10.2172 14.6118 10.8467L14.1104 11.8497C13.9154 12.2232 13.5254 12.4653 13.1005 12.4653C11.5893 12.4653 10.3915 13.7311 10.489 15.2253L10.8511 20.9388C10.9347 22.3638 11.7077 23.5328 13.6298 23.5328H20.9283C22.8505 23.5328 23.6165 22.3638 23.7071 20.9388L24.0692 15.2253C24.1667 13.7311 22.9689 12.4653 21.4576 12.4653ZM16.2344 13.3299H18.3237C18.6093 13.3299 18.846 13.5651 18.846 13.8487C18.846 14.1323 18.6093 14.3675 18.3237 14.3675H16.2344C15.9489 14.3675 15.7121 14.1323 15.7121 13.8487C15.7121 13.5651 15.9489 13.3299 16.2344 13.3299ZM17.2791 20.8489C15.9837 20.8489 14.9252 19.8044 14.9252 18.5109C14.9252 17.2174 15.9768 16.1729 17.2791 16.1729C18.5814 16.1729 19.633 17.2174 19.633 18.5109C19.633 19.8044 18.5744 20.8489 17.2791 20.8489Z" fill="white" />
                                    </svg>
                                </div>

                                <div className='row_profile'>
                                <InputModal value={user?.name} name={"Nome *"} />
                                <InputModal value={user?.cpf} mask={"999.999.999-99"} name={"CPF *"}/>
                                </div>

                                <div className='row_profile'>
                                <InputModal value={user?.phone} name={"Telefone *"} mask={"(99) 99999-9999"} />
                                <InputModal value={user?.mail} name={"Email *"}/>
                                </div>

                                <div className='row_profile'>
                                <InputModal type={"password"} name={"Senha *"} />
                                <InputModal type={"password"} name={"Confirme sua senha *"}/>
                                </div>

                                <button>Salvar</button>

                            </div>

                        </div>

                    </div>

                    <div className='container_mobile'>

                        {openSidebar == true

                            ?
                            <div className='sidebar_open_background' onClick={() => setOpenSidebar(!openSidebar)}>
                            </div>
                            :
                            <></>

                        }
                        <HeaderDashboardMobile onClick={() => setOpenSidebar(true)} />
                        <SidebarMobile state={openSidebar} />

                        <div className='header_container_mobile'>
                            {/* <button className='btn_consult_mobile'>Consulta Clínica +</button> */}
                        </div>

                    
                        <div className='container_profile_mobile'>

                            <div className='box_profile_mobile'>

                                <BigHead
                                    width={137}
                                    accessory="shades"
                                    body="chest"
                                    circleColor="blue"
                                    clothing="tankTop"
                                    clothingColor="black"
                                    eyebrows="angry"
                                    eyes="wink"
                                    facialHair="mediumBeard"
                                    graphic="vue"
                                    hair="short"
                                    hairColor="black"
                                    hat="none"
                                    hatColor="green"
                                    lashes="false"
                                    lipColor="purple"
                                    mask="true"
                                    faceMask="true"
                                    mouth="open"
                                    skinTone="brown"
                                />

                                <div>
                                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1.06152" y="0.50769" width="32.4283" height="32.2024" rx="16.1012" fill="black" stroke="#D7D7D7" />
                                        <path d="M21.4576 12.4653C21.0328 12.4653 20.6428 12.2232 20.4478 11.8497L19.9464 10.8467C19.626 10.2172 18.7903 9.69844 18.08 9.69844H16.4852C15.7678 9.69844 14.9321 10.2172 14.6118 10.8467L14.1104 11.8497C13.9154 12.2232 13.5254 12.4653 13.1005 12.4653C11.5893 12.4653 10.3915 13.7311 10.489 15.2253L10.8511 20.9388C10.9347 22.3638 11.7077 23.5328 13.6298 23.5328H20.9283C22.8505 23.5328 23.6165 22.3638 23.7071 20.9388L24.0692 15.2253C24.1667 13.7311 22.9689 12.4653 21.4576 12.4653ZM16.2344 13.3299H18.3237C18.6093 13.3299 18.846 13.5651 18.846 13.8487C18.846 14.1323 18.6093 14.3675 18.3237 14.3675H16.2344C15.9489 14.3675 15.7121 14.1323 15.7121 13.8487C15.7121 13.5651 15.9489 13.3299 16.2344 13.3299ZM17.2791 20.8489C15.9837 20.8489 14.9252 19.8044 14.9252 18.5109C14.9252 17.2174 15.9768 16.1729 17.2791 16.1729C18.5814 16.1729 19.633 17.2174 19.633 18.5109C19.633 19.8044 18.5744 20.8489 17.2791 20.8489Z" fill="white" />
                                    </svg>
                                </div>
                            </div>

                            <InputModal name={"Nome *"} value={user?.name} />
                            <InputModal name={"CPF *"} value={user?.cpf} />
                            <InputModal name={"Telefone *"} value={user?.phone} />
                            <InputModal name={"Email *"} value={user?.mail} />
                            <InputModal name={"Senha *"} />
                            <InputModal name={"Confirme sua senha *"} />

                            <button>Salvar</button>

                        </div>



                    </div>
                </div>

            }>

        </ThowColumLayoutDashboard>
    )
}

export default Account