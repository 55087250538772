import React from 'react'
import Container from '../../components/Container'
import Header from '../../components/Header'
import HeaderMobile from '../../components/HeaderMobile'
import HeaderSecondary from '../../components/HeaderSecondary'
import ContainerPaymentProduct from '../../components/ContainerPaymentProduct'
import SidebarCart from '../../components/SidebarCart'
import PaymentIcons from '../../components/PaymentIcons'
import SidebarFilter from '../../components/SidebarFilterTest';

const PaymentProduct = () => {
    return (
        <Container>
            <Header />
            <HeaderMobile />
            <HeaderSecondary/>
            <PaymentIcons type={3}/>
            <ContainerPaymentProduct/>
            <SidebarCart/>
            <SidebarFilter/>
        </Container>
    )
}

export default PaymentProduct