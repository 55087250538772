import React,{useState,useEffect} from 'react'

const ContainerCard = ({children,name,data}) => {
    
    const [view,setView] = useState(false)
    
    useEffect(() => {

        if(data){
            data?.map(item => {
                if(item.sessionName == name){
                    setView(true)
                }
            })
        }

    },[name,data,view])

    return(

        <div className='container_card'>
            {view == false 
            
            ?
            <></>
            :
            <>
            <p>{name}</p>
            {children}
            </>
            }

        </div>

    )
}

export default ContainerCard