import React from 'react'
import InputMask from 'react-input-mask';

const InputCupom = ({required,value,typeInput,id,onChange,onBlur,name,mask}) => {
    return(
      
        <div className='container_input_account'>
        <InputMask  required={required} value={value} type={typeInput} name='input_account' id={id} onChange={onChange} onBlur={onBlur} className='input_account' mask={mask} />
        <label htmlFor={id}>{name}</label>
        </div>
    )
} 

export default InputCupom